// scoreboardStore.ts
import { defineStore } from 'pinia';
import { createStore, Classic, Color } from 'matrix-display-store';
import { LedMatrix } from 'led-matrix';
import { useCanoePolo, useNetball, useHockey } from '~/scoreboards';
import { useBreakpoints } from '@vueuse/core'

const breakpoints = useBreakpoints({
    mobile: 0,
    tablet: 768,
    laptop: 1024,
    desktop: 1280,
})

const mobile = breakpoints.between('mobile', 'tablet')
const tablet = breakpoints.between('tablet', 'laptop')

interface ScoreboardOptions {
    sportType: string;
    autostart: boolean;
}

const height = 64;
const width = 96;

export const useScoreboardStore = defineStore('scoreboard', () => {
    const store = ref<ReturnType<typeof createStore>>();
    const matrix = ref<LedMatrix | null>(null);

    const sportType = ref<string>();
    const setSportType = (type: string) => {
        sportType.value = type;
    }

    const time = ref(600);

    const gameStarted = ref(false);
    const paused = ref(true);
    const scoreA = ref(0);
    const scoreB = ref(0);

    const shotClockTime = ref(60);
    const timePassed = ref(0);
    const shotClockPassed = ref(0);

    const timeLeft = computed(() => time.value - timePassed.value);

    const formattedTimeLeft = computed(() => {
        const minutes = Math.floor(timeLeft.value / 60);
        const seconds = timeLeft.value % 60;
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    });

    const formattedShotClock = computed(() => {
        let remainingTime = shotClockTime.value - shotClockPassed.value;
        return remainingTime > 0 ? remainingTime : 0;
    });

    function createScoreboard(canvasElement: HTMLCanvasElement | undefined, container: HTMLDivElement | undefined, options: ScoreboardOptions) {
        if (!canvasElement || !container) {
            return;
        }

        const { width: containerWidth, height: containerHeight } = useElementSize(container);

        const margin = computed(() => {
            return mobile.value || tablet.value ? 0.5 : 2;
        });

        const sizing = computed(() => {
            const aspectRatio = width / height;
            const containerAspectRatio = containerWidth.value / containerHeight.value;

            let pixelSize;
            if (containerAspectRatio > aspectRatio) {
                // Calculate pixel size based on height, including margins
                pixelSize = (containerHeight.value - (height + 1) * margin.value) / height;
            } else {
                // Calculate pixel size based on width, including margins
                pixelSize = (containerWidth.value - (width + 1) * margin.value) / width;
            }

            return {
                x: width,
                y: height,
                m: margin.value,
                p: pixelSize,
            };
        });

        store.value = createStore(width, height);
        matrix.value = new LedMatrix(canvasElement, {
            x: sizing.value.x,
            y: sizing.value.y,
            pixelWidth: sizing.value.p,
            pixelHeight: sizing.value.p,
            margin: sizing.value.m,
            glow: true
        });

        // Watch for changes in the container size
        watch([containerWidth], () => {
            matrix.value?.setNewOptions({
                x: sizing.value.x,
                y: sizing.value.y,
                pixelWidth: sizing.value.p,
                pixelHeight: sizing.value.p,
                margin: sizing.value.m,
            })

            setSportType('canoe-polo');
            updateDisplay();
        });


        // Start the default sport
        setSportType('canoe-polo');
        if (options.autostart) {
            startGame();
        }
        updateDisplay();

    }

    watch(sportType, (newSportType) => {
        updateDisplay();
    });

    function updateDisplay() {
        if (!store.value || !matrix.value) {
            return;
        }

        store.value.fillScreen(null);

        if (sportType.value == 'hockey') {
            useHockey({
                display: store.value,
                matrix: matrix.value,
                gameStarted: gameStarted.value,
                formattedTimeLeft: formattedTimeLeft.value,
                formattedShotClock: formattedShotClock.value,
                scoreA: scoreA.value,
                scoreB: scoreB.value,
            })
        } else if (sportType.value == 'netball') {
            useNetball({
                display: store.value,
                matrix: matrix.value,
                gameStarted: gameStarted.value,
                formattedTimeLeft: formattedTimeLeft.value,
                formattedShotClock: formattedShotClock.value,
                scoreA: scoreA.value,
                scoreB: scoreB.value,
            })
        } else if (sportType.value == 'canoe-polo') {
            useCanoePolo({
                display: store.value,
                matrix: matrix.value,
                gameStarted: gameStarted.value,
                formattedTimeLeft: formattedTimeLeft.value,
                formattedShotClock: formattedShotClock.value,
                scoreA: scoreA.value,
                scoreB: scoreB.value,
            })
        }

        matrix.value?.render();
    }

    const globalTimerInterval = ref();
    const startGame = () => {
        if (gameStarted.value) {
            paused.value = !paused.value;
            return;
        }

        gameStarted.value = true;
        paused.value = false;
        globalTimerInterval.value = setInterval(() => {
            if (!paused.value) {
                timePassed.value++;
                shotClockPassed.value++;
            }
            updateDisplay();
        }, 1000);
    };

    function incrementScoreA() {
        scoreA.value++;
        updateDisplay();
    }

    function incrementScoreB() {
        scoreB.value++;
        updateDisplay();
    }

    function decrementScoreA() {
        if (scoreA.value <= 0) {
            return;
        }
        scoreA.value--;
        updateDisplay();
    }

    function decrementScoreB() {
        if (scoreB.value <= 0) {
            return;
        }
        scoreB.value--;
        updateDisplay();
    }

    function $reset() {
        clearInterval(globalTimerInterval.value);
        timePassed.value = 0;
        shotClockPassed.value = 0;
        gameStarted.value = false;
        paused.value = true;
        scoreA.value = 0;
        scoreB.value = 0;
        updateDisplay();
    }

    return {
        createScoreboard,
        $reset,
        time,
        formattedTimeLeft,
        formattedShotClock,
        incrementScoreA,
        incrementScoreB,
        decrementScoreA,
        decrementScoreB,
        scoreA,
        scoreB,
        handlePlayButtonPress: () => {
            if (gameStarted.value) {
                paused.value = !paused.value;
            } else {
                startGame();
            }
        },
        resetShotClock: () => {
            shotClockPassed.value = 0;
            updateDisplay();
        },
        isPaused: computed(() => paused.value ? true : false),
        sportType,
        setSportType,
    };
});