import { Classic, Color, type IFont, type IRGBA, type Store } from 'matrix-display-store';
import { LedMatrix } from 'led-matrix';

const DISPLAY_WIDTH = 96;
const DISPLAY_HEIGHT = 64;

export interface Scoreboard {
    display: Store;
    matrix: LedMatrix | undefined;
    gameStarted: boolean;
    formattedTimeLeft: string;
    formattedShotClock: number;
    scoreA: number;
    scoreB: number;
}

interface Vector {
    x: number;
    y: number;
}

export interface FlexItem {
    text: string;
    font: IFont;
    size: number;
    color: IRGBA;
    width: number;
    height: number;
}

// Utility functions for grid positioning
export function createVector(x: number, y: number): Vector {
    return { x, y };
}

export function setFlexItem(display: Store, position: Vector, text: string, font: IFont, size: number, color: IRGBA) {
    display.write(position.x, position.y, text, font, size, color);
}

export function calculateWidth(text: string, fontSize: number): number {
    return text.length * fontSize * 6; // Rough estimate of character width
}

export function calculateHeight(fontSize: number): number {
    return fontSize * 8; // Rough estimate of character height
}

export function setFlexRow(display: Store, items: FlexItem[], y: number, justify: 'start' | 'center' | 'between' = 'start', gap: number = 2) {
    let totalWidth = items.reduce((acc, item) => acc + item.width + gap, -gap);

    let currentX = 0;
    if (justify === 'center') {
        currentX = (DISPLAY_WIDTH - totalWidth) / 2;
    } else if (justify === 'between') {
        gap = (DISPLAY_WIDTH - totalWidth) / (items.length - 1);
        currentX = 0;
    } else {
        currentX = 0;
    }

    items.forEach(item => {
        setFlexItem(display, createVector(currentX, y), item.text, item.font, item.size, item.color);
        currentX += item.width + gap;
    });
}

export function setFlexColumn(display: Store, items: FlexItem[], x: number, justify: 'start' | 'center' | 'between' = 'start', gap: number = 2) {
    let totalHeight = items.reduce((acc, item) => acc + item.height + gap, -gap);

    let currentY = 0;
    if (justify === 'center') {
        currentY = (DISPLAY_HEIGHT - totalHeight) / 2;
    } else if (justify === 'between') {
        gap = (DISPLAY_HEIGHT - totalHeight) / (items.length - 1);
        currentY = 0;
    } else {
        currentY = 0;
    }

    items.forEach(item => {
        setFlexItem(display, createVector(x, currentY), item.text, item.font, item.size, item.color);
        currentY += item.height + gap;
    });
}

export function parseXML(xmlString: string, scoreboard: Scoreboard): void {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlString, "text/xml");
    const scoreboardElement = xmlDoc.getElementsByTagName("Scoreboard")[0];

    if (!scoreboardElement) {
        throw new Error("No Scoreboard element found in the XML string.");
    }

    function parseTextElement(element: Element): void {
        const x = parseInt(element.getAttribute('x') || '0');
        const y = parseInt(element.getAttribute('y') || '0');
        const size = parseInt(element.getAttribute('size') || '1');
        const color = Color.hex(element.getAttribute('color') || "#ffffff");
        const text = element.textContent || "";

        setFlexItem(scoreboard.display, createVector(x, y), text, Classic, size, color);
    }

    function parseRowElement(element: Element): void {
        const y = parseInt(element.getAttribute('y') || '0');
        const justify = element.getAttribute('justify') as 'start' | 'center' | 'between' || 'start';
        const gap = parseInt(element.getAttribute('gap') || '2');
        const items = Array.from(element.getElementsByTagName("Text")).map(parseTextElementData);

        setFlexRow(scoreboard.display, items, y, justify, gap);
    }

    function parseColumnElement(element: Element): void {
        const x = parseInt(element.getAttribute('x') || '0');
        const justify = element.getAttribute('justify') as 'start' | 'center' | 'between' || 'start';
        const gap = parseInt(element.getAttribute('gap') || '2');
        const items = Array.from(element.getElementsByTagName("Text")).map(parseTextElementData);

        setFlexColumn(scoreboard.display, items, x, justify, gap);
    }

    function parseTextElementData(element: Element): FlexItem {
        const size = parseInt(element.getAttribute('size') || '1');
        const color = Color.hex(element.getAttribute('color') || "#ffffff");
        const text = element.textContent || "";
        return {
            text: text,
            font: Classic,
            size: size,
            color: color,
            width: calculateWidth(text, size),
            height: calculateHeight(size)
        };
    }

    Array.from(scoreboardElement.getElementsByTagName("Text")).forEach(parseTextElement);
    Array.from(scoreboardElement.getElementsByTagName("Row")).forEach(parseRowElement);
    Array.from(scoreboardElement.getElementsByTagName("Column")).forEach(parseColumnElement);

    if (scoreboard.matrix instanceof LedMatrix) {
        scoreboard.matrix.render();
    }
}