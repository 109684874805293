import { OrgDot, Classic, Color } from 'matrix-display-store';
import { LedMatrix } from 'led-matrix';
import { createVector, setFlexItem } from './util';
import type { Scoreboard } from './util';

export function useCanoePolo(scoreboard: Scoreboard) {
    if (scoreboard.matrix instanceof LedMatrix) {
        scoreboard.matrix.setData(scoreboard.display.matrix);
    }

    const timePosition = scoreboard.gameStarted ? createVector(19, 2) : createVector(19, 2);
    setFlexItem(scoreboard.display, timePosition, scoreboard.formattedTimeLeft, OrgDot, 3, Color.hex("#fbbf24"));

    const shotClockText = scoreboard.formattedShotClock > 9 ? scoreboard.formattedShotClock.toString() : " " + scoreboard.formattedShotClock.toString();
    setFlexItem(scoreboard.display, createVector(36, 26), shotClockText, OrgDot, 2, Color.hex("#dc2626"));

    const scoreAPosition = createVector(scoreboard.scoreA > 9 ? 2 : 2, scoreboard.scoreA > 9 ? 36 : 36);
    const scoreBPosition = createVector(scoreboard.scoreB > 9 ? 73 : 79, scoreboard.scoreB > 9 ? 36 : 36);

    setFlexItem(scoreboard.display, scoreAPosition, scoreboard.scoreA.toString(), OrgDot, scoreboard.scoreA > 99 ? 2 : 3, Color.hex("#2563eb"));
    setFlexItem(scoreboard.display, scoreBPosition, scoreboard.scoreB.toString(), OrgDot, scoreboard.scoreB > 99 ? 2 : 3, Color.hex("#16a34a"));

    const homeLabel = createVector(2, 54);
    const awayLabel = createVector(71, 54);

    setFlexItem(scoreboard.display, homeLabel, "Home", Classic, 1, Color.hex("#ffffff"));
    setFlexItem(scoreboard.display, awayLabel, "Away", Classic, 1, Color.hex("#ffffff"));

    if (scoreboard.matrix instanceof LedMatrix) {
        scoreboard.matrix.render();
    }
}
