<template>
    <div ref="container" class="relative gradient shadow-black shadow-xl flex p-3 rounded-lg min-h-1 min-w-1">
        <canvas class="override" ref="gridCanvas"></canvas>
    </div>
</template>

<script setup lang="ts">
import { useScoreboardStore } from '@/stores/scoreboard';

const scoreboard = useScoreboardStore();
const container = ref<HTMLDivElement | undefined>();
const gridCanvas = ref<HTMLCanvasElement>();

watch(
    () => gridCanvas.value,
    (newVal) => {
        if (newVal) {
            scoreboard.createScoreboard(newVal, container.value, {
                sportType: 'canoe-polo',
                autostart: true,
            });
        }
    },
);

onUnmounted(() => {
    scoreboard.$reset();
});
</script>

<style>
.override {
    width: 100% !important;
    height: 100% !important;
}

.gradient {
    background: linear-gradient(to bottom, rgb(47, 47, 47) 0%, rgb(18, 18, 18) 100%);
}
</style>
